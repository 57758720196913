.notification-page{
  .notification-wrapper {
    position: relative;
    padding: 1rem;
    .color-bar {
      width: 3px;
      background-color: rgb(180,99,240);
      position: absolute;
      top: 5px;
      bottom: 5px;
      border-radius: 30px;
    }
    .detail-wrapper {
      padding: 0.5rem;
      .detail-text {
        padding: 0 0.25rem;
        font-size: 11.8px;
        color: #848484;
      }
    }
    .title {
      padding-left: 1.25rem;
      padding-right: 1rem;
      font-size: 14.6px;
      font-weight: bold;
    }
  }
}