.p-0 {
  padding: 0;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.height-75 {
  height: 75px;
}
.height-200 {
  height: 200px;
}
.footer-container {
  padding: 0 !important;
}
.footer-logo-img {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.ant-select,
.ant-select-item {
  font-size: 16px !important;
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0px;
}

.ant-btn > .ant-btn-loading-icon {
  padding-right: 8px;
}

.react-timekeeper {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.footer-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0.6;
}

.lang-switch {
  cursor: pointer;
}

.ant-picker.date-picker {
  .ant-picker-input {
    input {
      text-transform: capitalize;
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    .ant-picker-panel {
      .ant-picker-date-panel {
        .ant-picker-header {
          .ant-picker-header-view {
            .ant-picker-month-btn {
              text-transform: capitalize;
            }
          }
        }
        .ant-picker-body {
          .ant-picker-content {
            thead tr th {
              text-transform: capitalize;
            }
          }
        }
      }
    }
    .ant-picker-month-panel {
      .ant-picker-body {
        .ant-picker-content {
          .ant-picker-cell {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
