.log-in-modal {
  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-form-item-explain,
  .log-in-modal,
  input {
    text-align: center;
  }

  .log-in-string {
    font-size : 17px;
    padding   : 1rem;
    text-align: center;
  }

  .login-logo-img {
    width: 100%;
    height: 100%; 
    padding: 20px;
  }
}