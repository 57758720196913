@import './assets/global.less';
@import './assets/layout';
@import './assets/notification';
@import './assets/shifts';
@import './assets/calendar';
@import './assets/login';
// @import './assets/fonts/fonts.css';
@import './assets/icomoon-fonts/style.css';
@import './assets/page404';
@import './assets/firebaseui-styling.global.css';
@primary-color: #22CCA5;