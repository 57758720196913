.shift-wrapper {
  padding: 1rem;

  .shift {
    color: white;
    border-radius: 10px;
    align-items: center;
    background-color: #b463f0;

    &.has-left-border {
      border-left: 8px solid #b463f0;
      background-color: #f2f2f2;
      color: black;
    }

    .shift-items {
      padding: 0.5rem;

      & > div {
        padding: 0.5rem;
      }

      .shift-item {
        display: flex;
        align-items: center;
      }
    }

    .claim-button,
    .cancel-button {
      border-bottom-left-radius: 0 !important;
      height: 100%;
      border: none;
      color: white;
      border-top-left-radius: 0px !important;

      i {
        margin-right: 0;
      }
    }

    .claim-button {
      background: #22cca5;
    }

    [class^='icon-'],
    [class*=' icon-'] {
      margin-right: 10px;
      font-size: 16px;
    }
  }
}

.shift-day {
  .day {
    font-size: 12px;
    color: #c1c1c1;
  }

  .date {
    font-size: 16px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin: auto;
  }

  &.active .date {
    background: #22cca5;
    color: white;
  }
}

.date-group {
  position: absolute;
  margin-left: auto;
  bottom: 0;
  left: 0;
  right: 0;
  margin-right: auto;

  .ant-radio-button-wrapper:first-child {
    border-radius: 8px 0 0 8px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0 8px 8px 0;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    top: 50%;
    transform: translateY(-50%);
    height: 40%;
  }

  .ant-radio-button-wrapper.no-border-right {
    border-right: 0px;
  }
}

.extra-space-40 {
  height: 40px;
}

.shift-card-button-black {
  background: #696969;
  color: #f4f4f4;
  border: #696969;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 7px;
}

.shift-card-button-black:active {
  border: #696969;
}

.shift-card-button-grey {
  background: #f0f0f0;
  color: #474747;
  border: #f0f0f0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 7px;
}

.shift-card-heading {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 5px;
}
.shift-main > div:first-child {
  .shift-card-heading-month {
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 25px;
    font-weight: 600;
    background-color: #ffffff;
  }
}
.shift-row {
  -webkit-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.45);
}
.shift-card-heading-month-top {
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 25px;
  font-weight: 600;
}
.shift-main > div:nth-child(2) {
  .shift-card-heading-month {
    visibility: hidden;
  }
}

.shift-card-span {
  @media only screen and (min-width: 320px) {
    margin-top: -155%;
    margin-left: -35px;
  }

  @media only screen and (min-width: 450px) {
    margin-top: -94%;
  }

  margin-top: -46%;
  width: max-content;
  font-size: 14px;
  margin-left: -12px;
}

.shift-card-span-div {
  @media only screen and (min-width: 320px) {
    margin-top: -155%;
    margin-left: 1px;
  }
  @media only screen and (min-width: 450px) {
    margin-top: -94%;
    margin-left: -12px;
  }
  margin-top: -100%;
  width: max-content;
  font-size: 14px;
  margin-right: -12px;
}

.shift-card-span-div-first {
  @media only screen and (min-width: 320px) {
    margin-top: -155%;
    margin-left: 34px;
  }
  @media only screen and (min-width: 450px) {
    margin-top: -94%;
    margin-left: 34px;
  }
  margin-top: -100%;
  width: max-content;
  font-size: 14px;
  margin-right: -12px;
}

.shift-card-span-div-last {
  @media only screen and (min-width: 320px) {
    margin-top: -155%;
    margin-left: -16px;
  }
  @media only screen and (min-width: 450px) {
    margin-top: -94%;
    margin-left: -14px;
  }
  margin-top: -100%;
  width: max-content;
  font-size: 14px;
  margin-right: -12px;
}

.shift-card-span-div-last-one {
  @media only screen and (min-width: 320px) {
    margin-top: -155%;
    margin-left: -26px;
  }
  @media only screen and (min-width: 450px) {
    margin-top: -94%;
    margin-left: -14px;
  }
  margin-top: -100%;
  width: max-content;
  font-size: 14px;
  margin-right: -12px;
}

.shift-card-span-div-between {
  @media only screen and (min-width: 320px) {
    margin-top: -155%;
    margin-left: -45px;
  }
  @media only screen and (min-width: 450px) {
    margin-top: -94%;
    margin-left: -37px;
  }
  margin-top: -100%;
  width: max-content;
  font-size: 14px;
  margin-right: -12px;
}

.shift-card-span-div-between-last {
  @media only screen and (min-width: 320px) {
    margin-top: -165%;
    margin-left: -62px;
  }
  @media only screen and (min-width: 450px) {
    margin-top: -94%;
    margin-left: -37px;
  }
  margin-top: -100%;
  width: max-content;
  font-size: 14px;
  margin-right: -12px;
}
.shift-card-span-both {
  margin-top: -46%;
  width: max-content;
  font-size: 14px;
}

.space-between {
  margin-left: -57px;
  margin-top: -18px;
}
.space-between-firstdiv {
  margin-left: -19px;
  margin-top: -18px;
}
.space-between-last-div {
  margin-left: -91px;
  margin-top: -18px;
}

.shift-card-main {
  padding-left: 10px;
}

.shift-card-span-bottom {
  @media only screen and (min-width: 320px) {
    margin-top: 60%;
  }

  @media only screen and (min-width: 450px) {
    margin-top: 60%;
  }
  margin-top: 24%;
  width: max-content;
  font-size: 14px;
  margin-right: -12px;
}

.shift-card-span-bottom-last {
  @media only screen and (min-width: 320px) {
    margin-top: 60%;
    margin-left: -28px;
  }

  @media only screen and (min-width: 450px) {
    margin-top: 60%;
  }
  margin-top: 24%;
  width: max-content;
  font-size: 14px;
  margin-right: -12px;
}

.shift-range {
  padding-left: 10px;
}

.shift-range-bottom {
  padding-bottom: 2px;
  padding-left: 10px;
}

.pd-bottom-10 {
  padding-bottom: 10px;
}

.shift-main {
  height: 92% !important;
}

.shift-card-sub {
  width: 96%;
}

.pd-bottom-35 {
  padding-bottom: 35px;
}

.pd-bottom-20 {
  padding-bottom: 20px;
}

.mt-18 {
  margin-top: 18px;
}
.mt-12 {
  margin-top: 12px;
}
.ml-20 {
  margin-left: -20px;
}

.ml-40 {
  margin-left: -40px;
}

.ml-60 {
  margin-left: -60px;
}

.ml-30 {
  margin-left: -30px;
}

.pr-5 {
  padding-right: 5px;
}
.pr-6 {
  padding-right: 6px;
}
.pl-78 {
  padding-left: 78px;
}

.pl-15 {
  padding-left: 15px;
}

.d-flex {
  display: flex;
}

.pd-12 {
  padding: 12px;
}

.center-display {
  display: flex;
  align-items: center;
}

.color-white {
  color: #f4f4f4;
}

.color-grey {
  color: #9d9d9d;
}

.linear-gradient-color {
  background: linear-gradient(
    89.97deg,
    #ff9900 15.5%,
    #fd2d53 44.36%,
    #3761f5 80.83%
  );
  text-align: center;
}

.linear-gradient {
  .one-four {
    height: 10px;
    width: 4.2%;
    background-color: #ff9900;
  }
  .four-five {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #ff9900, #ff771a);
  }
  .five-six {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #ff771a, #fe6f20);
  }
  .six-seven {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #fe6f20, #fe6726);
  }
  .seven-eight {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #fe6726, #fd4d3a);
  }
  .eight-nine {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #fd4d3a, #fd4d3a);
  }
  .nine-ten {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #fd4d3a, #fd4735);
  }
  .ten-eleven {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #fd4735, #f72f58);
  }
  .eleven-twelve {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #f72f58, #dd366d);
  }
  .twelve-thirteen {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #dd366d, #cf3979);
  }
  .thirteen-fourteen {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #cf3979, #b83f8b);
  }
  .fourteen-fifteen {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #b83f8b, #a5459c);
  }
  .fifteen-sixteen {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #a5459c, #8b49b0);
  }
  .sixteen-seventeen {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #8b49b0, #7f4fbb);
  }
  .seventeen-eighteen {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #7f4fbb, #7f4fbb);
  }
  .eighteen-nineteen {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #7f4fbb, #3761f5);
  }
  .eighteen-24 {
    height: 10px;
    width: 4.2%;
    background-image: linear-gradient(to right, #3761f5, #3761f5);
  }

  .yellow-yellow {
    height: 10px;
    width: 10%;
    background-image: linear-gradient(to right, #ff9900, #ff9900);
  }

  .yellow-orange {
    height: 10px;
    width: 10%;
    background-image: linear-gradient(to right, #ff9900, #ff9900);
  }

  .orange-orange {
    height: 10px;
    width: 10%;
    background-image: linear-gradient(to right, #ff9900, #fe5833);
  }

  .orange-red {
    height: 10px;
    width: 10%;
    background-image: linear-gradient(to right, #fe5833, #fe5833);
  }

  .red-red {
    height: 10px;
    width: 10%;
    background-image: linear-gradient(
      to right,
      #fe5833,
      #fd2d53 44.36%,
      #fd2d53 44.36%
    );
  }

  .red-pink {
    height: 10px;
    width: 10%;
    background-image: linear-gradient(to right, #fd2d53 44.36%, #c83f93);
  }

  .pink-purple {
    height: 10px;
    width: 10%;
    background-image: linear-gradient(to right, #c83f93, #c83f93, #824eb9);
  }

  .purple-blue {
    height: 10px;
    width: 10%;
    background-image: linear-gradient(to right, #824eb9, #824eb9);
  }

  .blue-blue {
    height: 10px;
    width: 20%;
    background-image: linear-gradient(
      to right,
      #824eb9,
      #3761f5 80.83%,
      #3761f5 80.83%
    );
  }
}

.shift {
  width: 100%;
  height: 100%;
}

.shift-card-text {
  padding: 10px;
}

.font-size {
  @media only screen and (max-width: 380px) {
    font-size: 11px;
  }

  font-size: 14px;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-text {
  @media only screen and (max-width: 360px) {
    font-size: 13px;
  }

  // @media only screen and (max-width: px) and (min-width: 320)  {...}
  color: white;
  font-weight: bolder;
}

.shift-header {
  z-index: 1;
  position: absolute;
  padding-top: 10px;
  right: 10px;
}

.shift-cancel {
  padding: 9px;
  justify-content: flex-end;
}

.add-shift {
  .ant-btn {
    background-color: #323232;
    border: #323232;
    color: white;
    height: 38px;
    padding: 4px 15px;
    font-weight: 600;
  }
}

.shift-form-date {
  display: flex;
  @media only screen and (max-width: 500px) {
    display: block;
    padding-left: 18px;
  }
}
