.calendar-page {
  .events {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .notes-month {
    text-align: center;
    font-size: 28px;
  }
  .notes-month section {
    font-size: 28px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border-top: none;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 25px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    font-size: 16px;
  }
}